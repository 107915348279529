/* eslint-disable no-use-before-define */
import React from 'react';

export default function Meats({ styles, data, fill }) {
    return (
        <>
          <svg className={`MuiSvgIcon-root ${styles}`} {...data} xmlns="http://www.w3.org/2000/svg" focusable="false" viewBox="0 0 512 512">
            <g fill={fill}>
              <path d="M93,334.247a8,8,0,0,1-10-12.494l17.66-14.128a24,24,0,1,0,10,12.49Z"/>
              <path d="M136,64H248a24.028,24.028,0,0,0,24-24V32H112v8A24.028,24.028,0,0,0,136,64Z"/>
              <path d="M432,168a8,8,0,0,1,16,0v38.987a31.987,31.987,0,0,0,15.468-52.723A8,8,0,0,1,462,145.606,23.764,23.764,0,0,0,464,136a24,24,0,0,0-48,0,23.764,23.764,0,0,0,2,9.606,8,8,0,0,1-1.464,8.658A31.987,31.987,0,0,0,432,206.987Z"/>
              <path d="M271.974,264.278a30.355,30.355,0,0,0,7.07,16.08c7.563,8.785,21.11,14.014,39.481,15.319a40.564,40.564,0,0,0-5.58-11.825C304.889,272.233,291.127,265.666,271.974,264.278Z"/>
              <path d="M453.8,256H426.2a24,24,0,0,0-23.946,25.6l.427,6.4H433.31a8,8,0,0,1,0,16H403.751l2.134,32H433.31a8,8,0,0,1,0,16H406.951l2.134,32H435.8a8,8,0,0,1,0,16H410.151l3.84,57.6A24.062,24.062,0,0,0,437.938,480h4.124a24.062,24.062,0,0,0,23.947-22.4l11.733-176A24,24,0,0,0,453.8,256Z"/>
              <path d="M331.462,343.212a8,8,0,0,1-6.925,0,114.883,114.883,0,0,0-22.72-7.459c-10.948-2.34-26.322-3.664-37.378,3.308C253.531,345.941,248,360.305,248,381.75c0,21.619,4.172,41.9,12.064,58.653,7.058,14.982,16.974,26.646,27.921,32.845,8.386,4.748,21.421,8.581,35.9-.108a8,8,0,0,1,8.232,0c9.074,5.444,18.585,6.62,28.264,3.493,14.389-4.648,27.545-18.44,36.315-37.941l-6.7-100.523C371.593,328.2,341.9,338.2,331.462,343.212Z"/>
              <path d="M93,142.247a8,8,0,0,1-10-12.494l17.66-14.128a24,24,0,1,0,10,12.49Z"/>
              <path d="M320,324.233V311.779c-24.474-1.253-42.406-8.377-53.323-21.264a47.182,47.182,0,0,1-10.568-35.83A8,8,0,0,1,264,248c20.2,0,47.47,4.782,62.656,27.562a56.87,56.87,0,0,1,9.3,27.218H336v21.452a128.144,128.144,0,0,1,16-4.357V48H287.2A40.068,40.068,0,0,1,248,80H136A40.068,40.068,0,0,1,96.805,48H32V400H232.955A174.8,174.8,0,0,1,232,381.75c0-27.327,8.057-46.252,23.946-56.249C276.573,312.524,304.521,318.943,320,324.233ZM133,302.247l-9.41,7.527a40.062,40.062,0,1,1-10-12.49L123,289.753a8,8,0,0,1,10,12.494Zm0-96-9.41,7.527a40.062,40.062,0,1,1-10-12.49L123,193.753a8,8,0,0,1,10,12.494Zm0-96-9.41,7.527a40.062,40.062,0,1,1-10-12.49L123,97.753a8,8,0,0,1,10,12.494ZM152,112H312a8,8,0,0,1,0,16H152a8,8,0,0,1,0-16Zm0,32h80a8,8,0,0,1,0,16H152a8,8,0,0,1,0-16Zm64,208H152a8,8,0,0,1,0-16h64a8,8,0,0,1,0,16Zm16-32H152a8,8,0,0,1,0-16h80a8,8,0,0,1,0,16Zm0-64H152a8,8,0,0,1,0-16h80a8,8,0,0,1,0,16Zm-80-32a8,8,0,0,1,0-16H312a8,8,0,0,1,0,16Z"/>
              <path d="M93,238.247a8,8,0,0,1-10-12.494l17.66-14.128a24,24,0,1,0,10,12.49Z"/>
              <path d="M448,223.33V240H432V223.33a48.1,48.1,0,0,0,16,0Z"/>
            </g>
          </svg>
        </>
    );
}