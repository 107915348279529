import React from 'react'
import { Helmet } from 'react-helmet';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import Button from '@material-ui/core/Button';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import DonutSmallIcon from '@material-ui/icons/DonutSmall';
import AlmTableIcon from '../components/icons/alimentos-table'
import SEO from '../components/seov2'
import "normalize.css"


const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  ButtonLks: {
    backgroundColor:'#ECFDF5',
    color:'#111827',
    textAlign:"center"
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  grid: {
    margin: '.5rem .5rem'
  },
  gridY: {
    margin: '.25rem 0'
  },
  MuiCard: {
    '& .MuiCardHeader-root': {
      padding: `${theme.spacing(2)}px`,
      '& .MuiCardHeader-content': {
        fontSize: '1rem',
        fontWeight: '600',
        color: '#000',
        textTransform: 'uppercase',
        textAlign: 'center',
      },
    },
    '& .MuiCardContent-root': {
      padding: `${theme.spacing(2)}px`,
      '& .MuiTypography-root': {
        fontWeight: '400',
        fontSize: '1.25rem',
        color: '#000',
        textAlign: 'center',
        '& small': {
          fontWeight: '200',
        }
      },
    },
  },
  BRRigth: {
    borderRadius: '4px 0 0 4px'
  },
  BRLeft: {
    borderRadius: '0 4px 4px 0'
  },
  BRTop: {
    borderRadius: '4px 4px 0 0'
  },
  BRBottom: {
    borderRadius: ' 0 0 4px 4px'
  },
  h1resultado: {
    //backgroundColor:"#DC2626",
    textDecoration: 'wavy underline  #111827',
    color: '#DC2626',
    fontWeight: '900',
    textTransform: 'uppercase',
    letterSpacing: '.15rem',
    lineHeight: '2.5rem',
    textAlign: 'center',
    borderRadius: 4,
    padding: 4
  },
  h1atual: {
    //backgroundColor:"#059669",
    textDecoration: 'wavy underline  #111827',
    color: '#059669',
    fontWeight: '900',
    textTransform: 'uppercase',
    letterSpacing: '.15rem',
    lineHeight: '2.5rem',
    textAlign: 'center',
    borderRadius: 4,
    padding: 4
  },
  h1quest: {
    //backgroundColor:"#2563EB",
    textDecoration: 'wavy underline  #111827',
    color: '#2563EB',
    fontWeight: '900',
    textTransform: 'uppercase',
    letterSpacing: '.15rem',
    lineHeight: '2.5rem',
    textAlign: 'center',
    borderRadius: 4,
    padding: 4
  },
  objtmb: {
    '& > div': {
      borderTop: '1px dashed #9CA3AF',
      padding: `${theme.spacing(.5)}px 0`
    },
    '& > div:first-child': {
      borderTop: 'none',
    }
  },

  instagram: {
    display: 'inline-block',
    // width: '250px',
    height: '32px',
    textAlign: 'center',
    borderRadius: '8px',
    color: '#fff',
    fontSize: '.5rem',
    //lineHeight: '250px',
    verticalAlign: 'middle',
    background: '#d6249f radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%)',
    boxShadow: '0px 3px 10px rgba(0,0,0,.25)',
  }

}));

const IndexPage = (s) => {

  const classes = useStyles();
  const theme = useTheme();
  const matchesBK = useMediaQuery(theme.breakpoints.down('xs'));
  const downXS = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <>

      <Helmet>

        <style>
          {`html {
              scrollbar-color: #666 #201c29;
              background-color: none;
              color: none;
              overflow-x: hidden;
              font-family: Roboto, Verdana, Geneva, Tahoma, sans-serif;
          }
          body{
              margin: 0 0 0 0;
              padding: 0 0 0 0;
          }

          body:after {
              content: "";
              background: none
          }

          body:after,body:before {
              display: none;
              height:none;
              position: none;
              top: none;
              left: none;
              width: none;
          }

          body:before {
              background-size: none
          }

          body:after,body:before {
              -webkit-clip-path: none;
              clip-path: none
          }

          main {
              position: relative;
              z-index: 1;
          }`}
        </style>

        <style>
          {
            `
          @keyframes gradientMoviment {
            0% {
              background-position: 0% 50%;
            }
            50% {
              background-position: 100% 50%;
            }
            100% {
              background-position: 0% 50%;
            }
          }
          @keyframes gradientMoviment2 {
            0% {
              background-position: 0% 50%;
            }
            50% {
              background-position: 100% 50%;
            }
            100% {
              background-position: 0% 50%;
            }
          }
          `
          }
        </style>


      </Helmet>

      <SEO
        description={'INFO Nutrientes LinkTree'}
        title={'INFO Nutrientes LinkTree'}
      />

      {/* header navbar */}
      <Grid container direction="row" wrap="wrap" justify='center' alignItems="center" alignContent="center"
        xs={12} sm={12} md={12} lg={12} xl={12}
        style={{ backgroundColor: '#ECFDF5', }}
      >
        <Grid container direction="row" wrap="wrap" justify='center' alignItems="center" alignContent="center"
          xs={12} sm={12} md={12} lg={12} xl={12}
          style={{
            margin: 'auto', padding: '16px',
            background: 'linear-gradient(135deg, #10B981 33%, #047857 66%, #064E3B 100%)',
            backgroundSize: '200% 200%',
            animation: 'gradientMoviment 15s ease infinite',
            clipPath: 'polygon(0 0, 100% 0, 100% 80%, 0 100%)'
          }}
        >
          <Grid container direction="row" wrap="wrap" justify="space-between" alignItems="center" alignContent="center"
            xs={12} sm={12} md={12} lg={10} xl={10}>

            <a href="https://infonutrientes.com.br" rel="noreferrer" target="_blank" style={{textDecoration: 'none'}}>
              <Typography component="h1" variant="h5" gutterBottom
                style={{ color: '#fff', fontWeight: "900", }}
              //border:'1px solid #047857', padding:'4px', borderRadius:'8px', backgroundColor:"#064E3B"
              >
                INFO Nutrientes
              </Typography>
            </a>

            <div>
              <a href="https://www.facebook.com/infonutrientes" rel="noreferrer" target="_blank">
                <FacebookIcon style={{ fill: '#fff', margin: '0 16px 0 0' }} />
              </a>
              <a href="https://www.instagram.com/infonutrientes" rel="noreferrer" target="_blank">
                <InstagramIcon style={{ fill: '#fff' }} />
              </a>
            </div>

          </Grid>
        </Grid>
      </Grid>

      {/* main content */}
      <Grid container direction="row" wrap="wrap" justify="center" alignItems="center" alignContent="center"
        xs={12} sm={12} md={12} lg={12} xl={12}
        style={{ margin: 'auto', padding: '0', backgroundColor: "#ECFDF5" }}
      >

        {/* frase */}
        <Grid container xs={12} sm={12} md={12} lg={12} xl={12}
          justify="center" alignItems="center" alignContent="center"
        //style={{backgroundColor:'#374151'}}
        >
          <Typography component="p" variant="body1"
            style={{ padding: '8px 0', textAlign: "center", color: '#111827', fontsize: '.75rem', fontWeight: "300", letterSpacing: '.15rem' }}
          >
            sua saúde é o reflexo da sua alimentação
          </Typography>
        </Grid>

        <Grid container xs={12} sm={12} md={12} lg={12} xl={12}
          justify="center" alignItems="center" alignContent="center"
          style={{
            background:'linear-gradient(135deg, #10B981 33%, #047857 66%, #064E3B 100%)',
            backgroundSize:'200% 200%',
            animation:'gradientMoviment 15s ease infinite',
            clipPath:'polygon(0 5%, 100% 0, 100% 100%, 0 95%)',
          }}
        >

        
        {/* header title top*/}
        <Grid container xs={12} sm={12} md={12} lg={10} xl={10}
            justify="center" alignItems="center" alignContent="center"
            //className={classes.grid}
            style={{
              textAlign: "center",
              // background:'linear-gradient(135deg, #10B981 33%, #047857 66%, #064E3B 100%)',
              // backgroundSize:'200% 200%',
              // animation:'gradientMoviment 15s ease infinite',
              //clipPath: 'polygon(0 20%, 100% 0, 100% 100%, 0 100%)',
              //transform:'rotate(1deg)',
              //borderRadius:'1rem 1rem 0 0',
            }}
          >
            <Typography component="h1" variant="h5" gutterBottom
              style={{
                color:'white',
                fontWeight:'bold',
                fontSize:'2rem',
                //transform:'rotate(-1deg)',
                padding:'1rem 0',
                margin:'1rem 0 0',
                lineHeight:'1.75rem'
              }}>
              
            </Typography>
          </Grid>

        {/* main content container */}
        <Grid
          container direction="column" justify="center" alignItems="center" alignContent="center"
          spacing={0} xs={12} sm={12} md={12} lg={10} xl={10}
          elevation={3}
          className={'MuiPaper-root MuiPaper-elevation8 MuiPaper-rounded'}
          style={{
            padding: '1.5rem auto',
            //marginBottom: '1.5rem',
            // background:'linear-gradient(135deg, #10B981 33%, #047857 66%, #064E3B 100%)',
            // backgroundSize:'200% 200%',
            // animation:'gradientMoviment 15s ease infinite',
          }}
        >

          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}
            justify="center" alignItems="center" alignContent="center"
            className={classes.grid}
          >
            <Button href="/calcular-taxa-metabolica-basal"
              startIcon={<FlashOnIcon />}
              variant="contained"  size={'large'} className={classes.ButtonLks}
            >
              <span style={{lineHeight:'1rem'}}> 
                <strong>Taxa Metabólica</strong> <br/>
                <small style={{textTransform:'lowercase'}}>
                  planeje sua ingestão energética para emagrecimento
                </small>
              </span>
            </Button>

          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}
            justify="center" alignItems="center" alignContent="center"
            className={classes.grid}
          >
            <Button href="/calcular-macronutrientes-planejamento-alimentar"
              startIcon={<DonutSmallIcon />}
              variant="contained"  size={'large'} className={classes.ButtonLks}
            >
              <span style={{lineHeight:'1rem'}}>
                <strong>Macronutrientes</strong> <br/>
                <small style={{textTransform:'lowercase'}}>
                  planeje sua ingestão nutricional para emagrecimento
                </small>
              </span>
            </Button>

          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}
            justify="center" alignItems="center" alignContent="center"
            className={classes.grid}
          >
            <Button href="/calculo-nutricional-por-refeicao"
              startIcon={<RestaurantIcon />}
              variant="contained" size={'large'} className={classes.ButtonLks}
            >
              <span style={{lineHeight:'1rem'}}>
                <strong>Refeição</strong> <br/>
                <small style={{textTransform:'lowercase'}}>
                  calcule a tabela nutricional das suas refeições
                </small>
              </span>
            </Button>

          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}
            justify="center" alignItems="center" alignContent="center"
            className={classes.grid}
          >
            <Button href="/"
              startIcon={<AlmTableIcon />}
              variant="contained" size={'large'} className={classes.ButtonLks}
            >
              <span style={{lineHeight:'1rem'}}>
                <strong>Alimentos</strong> <br/>
                <small style={{textTransform:'lowercase'}}>
                  descubra a composição nutricional deles
                </small>
              </span>
            </Button>

          </Grid>

        </Grid>

        {/* header title bottom*/}
        <Grid container xs={12} sm={12} md={12} lg={10} xl={10}
            justify="center" alignItems="center" alignContent="center"
            //className={classes.grid}
            style={{
              textAlign: "center",
              // background:'linear-gradient(135deg, #10B981 33%, #047857 66%, #064E3B 100%)',
              // backgroundSize:'200% 200%',
              // animation:'gradientMoviment 15s ease infinite',
              //clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 80%)',
              //transform:'rotate(1deg)',
              //borderRadius:'1rem 1rem 0 0',
            }}
          >
            <Typography component="h1" variant="h5" gutterBottom
              style={{
                color:'white',
                fontWeight:'bold',
                fontSize:'2rem',
                //transform:'rotate(-1deg)',
                padding:'1rem 0',
                margin:'0 0 1rem 0',
                lineHeight:'1.75rem'
              }}>
              
            </Typography>
          </Grid>

        </Grid>

        {/* frase */}
        <Grid container xs={12} sm={12} md={12} lg={12} xl={12}
          justify="center" alignItems="center" alignContent="center"
        //style={{backgroundColor:'#374151'}}
        >
          <Typography component="p" variant="body1"
            style={{ padding: '8px 0', textAlign: "center", color: '#111827', fontsize: '.75rem', fontWeight: "300", letterSpacing: '.15rem' }}
          >
            sua saúde é o reflexo da sua alimentação
          </Typography>
        </Grid>

    </Grid>
    
    {/* footer navbar */ }
      <Grid container direction="row" wrap="wrap" justify='center' alignItems="center" alignContent="center"
        xs={12} sm={12} md={12} lg={12} xl={12}
        style={{ backgroundColor: '#ECFDF5', }}
      >
        <Grid container direction="row" wrap="wrap" justify='center' alignItems="center" alignContent="center"
          xs={12} sm={12} md={12} lg={12} xl={12}
          style={{
            margin: 'auto', padding: '16px',
            background: 'linear-gradient(135deg, #10B981 33%, #047857 66%, #064E3B 100%)',
            backgroundSize: '200% 200%',
            animation: 'gradientMoviment 15s ease infinite',
            clipPath: 'polygon(0 0, 100% 20%, 100% 100%, 0 100%)'
          }}
        >
          <Grid container direction="row" wrap="wrap" justify="space-between" alignItems="center" alignContent="center"
            xs={12} sm={12} md={12} lg={10} xl={10}>
            <a href="https://infonutrientes.com.br" rel="noreferrer" target="_blank" style={{textDecoration: 'none'}}>
              <Typography component="h1" variant="h5" gutterBottom
                style={{ color: '#fff', fontWeight: "900", }}
              //border:'1px solid #047857', padding:'4px', borderRadius:'8px', backgroundColor:"#064E3B"
              >
                INFO Nutrientes
              </Typography>
            </a>

            <div>

              <a href="https://www.facebook.com/infonutrientes" rel="noreferrer" target="_blank">
                <FacebookIcon style={{ fill: '#fff', margin: '0 16px 0 0' }} />
              </a>

              <a href="https://www.instagram.com/infonutrientes" rel="noreferrer" target="_blank">
                <InstagramIcon style={{ fill: '#fff' }} />
              </a>

            </div>

          </Grid>
        </Grid>
      </Grid>

    </>
  )
}

export default IndexPage;
